import React from 'react';
import styled from 'styled-components';
// Components
import { useTranslation } from 'react-i18next';
import PricingTable from '../Elements/PricingTable';
import i18n from '../../core/i18n';

const renderPricing = () => {
  const itemsArray = i18n.t('pricing.items', { returnObjects: true });
  const iconsArray = ['group', 'individual', 'exams', 'couple', 'couple'];
  // @ts-ignore
  return itemsArray.map((item, index) => {
    return (
      <TableBox>
        <PricingTable
          icon={iconsArray[index]}
          price={item.price}
          title={item.title}
          subtitle={item.subtitle}
          offers={item.points.map((el) => ({
            name: el,
            checked: true,
          }))}
        />
      </TableBox>
    );
  });
};
export default function Pricing() {
  const { t } = useTranslation('translation', { keyPrefix: 'pricing' });
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t('title')}</h1>
          </HeaderInfo>
          <FlexContainer>{renderPricing()}</FlexContainer>
        </div>
      </div>
    </Wrapper>
  );
}

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px; // Adjust the space between items
`;

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 20px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 95%;
    margin: 0 auto;
  }
`;
